/* eslint-disable @typescript-eslint/no-var-requires */
export interface IconSet {
  [key: string]: [width: number, height: number, paths: string]
}

import fa from './../assets/icons/fa.json'
import logos from './../assets/icons/logos.json'

const families = {
  fa: fa,
  logo: logos
}
type IconFamily = keyof typeof families

interface IconProps {
  family: IconFamily
  name: string
  classList: string[]
}

function _getIcon(
  family: IconFamily,
  name: string
): { width: number; height: number; paths: string[] } {
  const set = (families[family] as unknown) as IconSet
  if (set !== undefined) {
    const values: [number, number, string] = set[name] ?? [16, 16, '']
    return {
      width: values[0],
      height: values[1],
      paths: values[2].split('|')
    }
  }

  return {
    width: 16,
    height: 16,
    paths: []
  }
}

export default function Icon({
  family,
  name,
  classList
}: IconProps): JSX.Element {
  const classNames = ['svg-inline', `${family}-${name}`, ...classList]
  const icon = _getIcon(family, name)
  const viewBox = [0, 0, icon.width, icon.height]
  const paths = icon.paths.map((path, i) => (
    <path
      key={i}
      className={['path', `path-${i}`].join(' ')}
      fill="currentColor"
      d={path}
    />
  ))
  return (
    <svg
      aria-hidden="true"
      role="img"
      className={classNames.join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox.join(' ')}
    >
      {paths}
    </svg>
  )
}
