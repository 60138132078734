/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormEvent, useState, useReducer } from 'react'

import axios from 'axios'

const api =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost/chantalnoeline.com/api'
    : 'https://chantalnoeline.com/api'

import Icon from './Icon'

const btnState = {
  title: 'Send',
  icon: 'paper-plane'
}
const btnReducer = (state: typeof btnState, pair: any): typeof btnState => ({
  ...state,
  ...pair
})

export default function Contact(): JSX.Element {
  const [submit, setSubmit] = useState(false)
  const [button, setButton] = useReducer(btnReducer, btnState)

  const classList = [
    'px-4 py-3 mt-2',
    'text-white placeholder-white placeholder-opacity-50',
    'bg-white bg-opacity-20',
    'border-2 border-transparent focus:border-white',
    'outline-none hover:outline-none focus:outline-none'
  ]

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (submit) return

    const formData = new FormData(
      e.currentTarget ?? (e.target as HTMLFormElement)
    )
    const payload: { [key: string]: string | null } = {}
    formData.forEach((value, key) => (payload[key] = (value as string) ?? null))
    for (const key in payload) {
      if (!payload[key]?.length) {
        const element = document.querySelector(`[name=${key}]`) as
          | HTMLElement
          | undefined
        element?.focus()
        return
      }
    }
    if (!/\S+@\w+\.[a-zA-Z]+/.test(payload.email ?? '')) {
      const element = document.querySelector('[name="email"]') as
        | HTMLInputElement
        | undefined
      element?.setSelectionRange(0, (payload.email ?? '').length)
      element?.focus()
      return
    }

    setButton({ title: 'Sending', icon: 'loading' })
    setSubmit(true)

    const options = {
      headers: { 'Content-Type': 'application/json' }
    }
    axios
      .post(`${api}/mail`, payload, options)
      .then((response) => {
        const data = response.data
        if (data.sent) {
          setButton({ title: 'Sent', icon: 'checkmark' })
        } else {
          setButton({ title: 'Error', icon: 'cross' })
          setSubmit(false)
        }
      })
      .catch((error) => {
        setSubmit(false)
        setButton(btnState)
      })
  }

  return (
    <div className="flex md:items-center md:justify-center h-full overflow-y-auto">
      <div className="flex-initial max-w-prose">
        <div className="px-4 sm:px-8 md:px-16 py-16">
          <h1 className="font-cursive text-5xl text-white">Contact</h1>
          <p className="mt-8">
            Please feel free to fill the form for any request!
          </p>
          <form className="mt-4" onSubmit={onSubmit} noValidate>
            <input
              name="name"
              type="text"
              placeholder="Name"
              className={['w-full', 'rounded-full', ...classList].join(' ')}
              required
            />
            <input
              name="email"
              type="text"
              placeholder="E-Mail"
              className={['w-full', 'rounded-full', ...classList].join(' ')}
              required
            />
            <textarea
              name="message"
              placeholder="Please to meet you"
              className={['w-full', 'rounded-lg', ...classList].join(' ')}
              rows={6}
            ></textarea>
            <button
              className="btn px-4 py-3 mt-4 font-bold bg-rose-700 hover:bg-white text-white hover:text-rose-700"
              type="submit"
            >
              <Icon family="fa" name={button.icon} classList={['mr-2']} />
              <span>{button.title}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
