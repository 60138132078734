import { createContext, useReducer, useEffect, useRef } from 'react'
import { playlist } from '../lib/constants'

const initialState = {
  songIndex: -1,
  play: false,
  preload: false,
  currentTime: 0,
  duration: 0,
  timeLeft: '',
  timeSpent: ''
}
export type AudioState = typeof initialState & { [key: string]: any }

export enum AudioActions {
  NEXT_SONG = 'next',
  UPDATE = 'update'
}

const reducer = (
  state: AudioState,
  handler: { action: AudioActions; payload?: { [key: string]: any } }
): AudioState => {
  switch (handler.action) {
    case AudioActions.NEXT_SONG:
      return {
        ...state,
        songIndex: state.songIndex + 1
      }
    default:
      return { ...state, ...(handler.payload ?? {}) }
  }
}

interface AudioContextProps {
  state: AudioState
  dispatch: (action: {
    action: AudioActions
    payload?: { [key: string]: any }
  }) => void
}

export const AudioContext = createContext({} as AudioContextProps)

export default function Audio(props: any): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState)

  const audioElement = useRef<HTMLAudioElement | null>(null)

  const canPlay = (): void => {
    dispatch({
      action: AudioActions.UPDATE,
      payload: {
        play: true,
        preload: true,
        duration: audioElement.current?.duration ?? 0
      }
    })
  }

  const onTime = (): void => {
    dispatch({
      action: AudioActions.UPDATE,
      payload: { currentTime: audioElement.current?.currentTime ?? 0 }
    })
  }

  useEffect(() => {
    audioElement.current?.addEventListener('canplaythrough', canPlay, false)
    audioElement.current?.addEventListener('timeupdate', onTime, false)
    audioElement.current?.addEventListener(
      'ended',
      () => dispatch({ action: AudioActions.NEXT_SONG }),
      false
    )

    return () => {
      audioElement.current?.removeEventListener(
        'canplaythrough',
        canPlay,
        false
      )
      audioElement.current?.removeEventListener('timeupdate', onTime, false)
      audioElement.current?.removeEventListener(
        'ended',
        () => dispatch({ action: AudioActions.NEXT_SONG }),
        false
      )
    }
  }, [audioElement])

  useEffect(() => {
    dispatch({
      action: AudioActions.UPDATE,
      payload: { preload: false }
    })

    audioElement.current?.load()

    if (state.songIndex > playlist.length - 1) {
      dispatch({
        action: AudioActions.UPDATE,
        payload: { songIndex: 0 }
      })
    }
  }, [state.songIndex])

  useEffect(() => {
    if (!state.preload) {
      return
    }
    audioElement.current?.play()
  }, [state.preload])

  useEffect(() => {
    if (!state.play) {
      audioElement.current?.pause()
    } else if (state.preload) {
      audioElement.current?.play()
    }
  }, [state.play])

  useEffect(() => {
    const total = Math.ceil(state.currentTime)
    const totalMin = Math.floor(total / 60)
    const totalSec = total - totalMin * 60
    const leftTime = Math.abs(Math.ceil(total - state.duration))
    const leftMin = Math.floor(leftTime / 60)
    const leftSec = leftTime - leftMin * 60
    dispatch({
      action: AudioActions.UPDATE,
      payload: {
        timeSpent: `${leftMin}:${leftSec < 10 ? '0' + leftSec : leftSec}`,
        timeLeft: `${totalMin}:${totalSec < 10 ? '0' + totalSec : totalSec}`
      }
    })
  }, [state.currentTime, state.duration])

  return (
    <AudioContext.Provider value={{ state, dispatch }}>
      {props.children}
      <audio
        ref={audioElement}
        className="absolute -top-96 -left-96 hidden"
        src={playlist[state.songIndex]?.src}
        preload="auto"
      >
        Your browser does not support the <code>audio</code> element.
      </audio>
    </AudioContext.Provider>
  )
}
