export default function About(): JSX.Element {
  return (
    <div className="flex md:items-center md:justify-center h-full overflow-y-auto">
      <div className="flex-initial max-w-prose">
        <div className="px-4 sm:px-8 md:px-16 py-16">
          <h1 className="font-cursive text-white text-5xl">Hello!</h1>
          <p className="text-lg mt-8 leading-loose">
            Chantal Noéline is a <strong className="text-white">gifted</strong>{' '}
            teacher who graduated from the Sorbonne University. She enjoys
            working with <strong className="text-white">children</strong> and
            does wonders in the classroom thanks to her{' '}
            <strong className="text-white">joyful</strong> attitude and{' '}
            <strong className="text-white">dynamic</strong> teaching style.
            “Hello”, her first musical book of songs was inspired by her
            extended experience teaching both French and English, in the US and
            France.
          </p>
          <p className="text-lg mt-4 leading-loose">
            She proposes an array of{' '}
            <strong className="text-white">songs</strong> dedicated to providing
            fun and well-being to our children and to the child within us!
          </p>
        </div>
      </div>
    </div>
  )
}
