/* eslint-disable @typescript-eslint/no-var-requires */

export interface Song {
  title: string
  src: string
}

export const playlist: Song[] = [
  { title: '11-21', src: require('./../../assets/mp3/11-21.mp3').default },
  {
    title: 'A Petit Pas',
    src: require('./../../assets/mp3/a_petit_pas.mp3').default
  },
  { title: 'ABC', src: require('./../../assets/mp3/abc_en.mp3').default },
  {
    title: 'ABC (French)',
    src: require('./../../assets/mp3/abc_fr.mp3').default
  },
  { title: 'Copycat', src: require('./../../assets/mp3/copycat.mp3').default },
  {
    title: 'Hello Goodbye',
    src: require('./../../assets/mp3/hello_goodbye.mp3').default
  },
  {
    title: 'I Have Two',
    src: require('./../../assets/mp3/i_have_two.mp3').default
  },
  {
    title: 'Little Cat',
    src: require('./../../assets/mp3/little_cat.mp3').default
  },
  {
    title: 'Say yes if you can',
    src: require('./../../assets/mp3/say_yes_if_you_can.mp3').default
  },
  {
    title: 'Tomatoes Potatoes',
    src: require('./../../assets/mp3/tomatoes_potatoes.mp3').default
  }
]
