/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import poster from './../assets/img/preview.jpg'
import animation from './../assets/vid/animation.mp4'

export default function Intro(): JSX.Element {
  const history = useHistory()
  const [preload, setPreload] = useState(false)
  const [muted, setMuted] = useState(true)
  const videoElement = useRef<HTMLVideoElement | null>(null)

  const canPlay = (): void => {
    setTimeout(() => {
      setPreload(true)
    }, 1000)
    videoElement.current?.play()
  }

  const onEnd = (): void => {
    history.push('/home')
  }

  useEffect(() => {
    videoElement.current?.addEventListener('canplaythrough', canPlay, false)
    videoElement.current?.addEventListener('ended', onEnd, false)
    return () => {
      videoElement.current?.removeEventListener(
        'canplaythrough',
        canPlay,
        false
      )
      videoElement.current?.removeEventListener('ended', onEnd, false)
      videoElement.current?.load()
    }
  }, [videoElement])

  return (
    <div className="fixed z-10 inset-0 bg-amber">
      <img
        className="relative z-0 w-full h-full object-cover"
        src={poster}
        data-loaded="false"
        onLoad={(e) => e.currentTarget?.setAttribute('data-loaded', 'true')}
      />
      <video
        ref={videoElement}
        className={[
          'block absolute inset-0 z-20',
          'w-full h-full object-cover',
          'transition duration-700',
          preload ? 'opacity-100' : 'opacity-0'
        ].join(' ')}
        poster={poster}
        muted={muted}
        preload="auto"
      >
        <source src={animation} type="video/mp4" />
      </video>
      <header className="absolute z-50 top-0 inset-x-0 px-8 py-8 text-center">
        <a className="inline-block font-cursive text-3xl sm:text-5xl text-white text-shadow hover:text-red-light">
          Chantal Noeline
        </a>
      </header>
      <div className="absolute z-50 inset-x-0 bottom-8 sm:bottom-16">
        <div className="flex justify-between px-8 font-cursive text-white">
          <div className="flex-initial px-2 py-1">
            <a
              className={[
                'inline-block text-xl',
                preload
                  ? 'cursor-pointer opacity-100'
                  : 'opacity-0 pointer-events-none'
              ].join(' ')}
              onClick={() => setMuted(!muted)}
            >
              {muted ? 'Sing' : 'Mute'}
            </a>
          </div>
          <div className="flex-initial px-2 py-1">
            <Link to="/home" className="inline-block text-xl">
              Skip Intro
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
