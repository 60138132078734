import { useContext } from 'react'

import { playlist } from './../lib/constants'

import Icon from './Icon'
import { AudioContext, AudioActions } from './Audio'

export default function Music(): JSX.Element {
  const { state, dispatch } = useContext(AudioContext)

  const sing = (index: number): void => {
    if (index === state.songIndex) {
      if (state.play) {
        dispatch({ action: AudioActions.UPDATE, payload: { play: false } })
      } else {
        dispatch({ action: AudioActions.UPDATE, payload: { play: true } })
      }
    } else {
      dispatch({ action: AudioActions.UPDATE, payload: { songIndex: index } })
    }
  }

  return (
    <div className="flex md:items-center md:justify-center h-full overflow-y-auto">
      <div className="flex-initial max-w-prose">
        <div className="px-4 sm:px-8 md:px-16 py-16">
          <h1 className="font-cursive text-5xl mb-8">Listen</h1>
          <table className="table text-xl">
            <tbody className="table-row-group">
              {playlist.map((item, i) => {
                return (
                  <tr
                    key={`row${i}`}
                    className="table-row group cursor-pointer border-b border-white border-opacity-50"
                    onClick={() => sing(i)}
                  >
                    <td className="table-cell w-8 text-center align-middle py-1 border-r border-white border-opacity-50">
                      {item.title === playlist[state.songIndex]?.title ? (
                        <Icon
                          family="fa"
                          name={!state.preload ? 'loading' : 'music'}
                          classList={[]}
                        />
                      ) : (
                        <span className="text-sm opacity-0 group-hover:opacity-100 transition duration-300">
                          <Icon family="fa" name="play" classList={[]} />
                        </span>
                      )}
                    </td>
                    <td className="table-cell text-right px-2 py-1 border-l border-white border-opacity-50">
                      {i + 1}.
                    </td>
                    <td className="table-cell text-left pl-2 py-1">
                      {item.title}
                    </td>
                  </tr>
                )
              })}
            </tbody>
            <tfoot className="table-row-group">
              <tr className="table-row">
                <td className="table-cell py-2" colSpan={3}>
                  <ul className="flex flex-nowrap items-center justify-between">
                    <li className="block text-sm">{state.timeLeft}</li>
                    <li className="block">
                      <ul className="flex flex-nowrap items-center space-x-2">
                        <li className="block flex-initial">
                          {state.songIndex === -1 ? (
                            <Icon family="fa" name="music" classList={[]} />
                          ) : (
                            <a
                              className="block cursor-pointer"
                              onClick={() =>
                                dispatch({
                                  action: AudioActions.UPDATE,
                                  payload: { play: !state.play }
                                })
                              }
                            >
                              <Icon
                                family="fa"
                                name={
                                  !state.preload
                                    ? 'loading'
                                    : state.play
                                    ? 'pause'
                                    : 'play'
                                }
                                classList={[]}
                              />
                            </a>
                          )}
                        </li>
                        <li className="block flex-auto">
                          <div className="relative w-32">
                            <span className="block bg-white bg-opacity-50 h-px -mt-px"></span>
                            <span
                              className="block absolute bg-white w-px h-4 -mt-2"
                              style={{
                                left: `${Math.round(
                                  (state.currentTime / state.duration) * 100
                                )}%`
                              }}
                            ></span>
                          </div>
                        </li>
                        <li className="block flex-initial">
                          <a
                            className="block cursor-pointer"
                            onClick={() =>
                              dispatch({ action: AudioActions.NEXT_SONG })
                            }
                          >
                            <Icon family="fa" name="forward" classList={[]} />
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="block text-sm">{state.timeSpent}</li>
                  </ul>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}
