import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { createBrowserHistory } from 'history'

import Intro from './views/Intro'
import Home from './views/Home'
import AudioProvider from './components/Audio'

const history = createBrowserHistory()

export default function App(): JSX.Element {
  return (
    <main className="block">
      <AudioProvider>
        <Router>
          <TransitionGroup>
            <CSSTransition
              key={history.location.key}
              classNames="fade"
              timeout={300}
            >
              <Switch>
                <Route exact path="/">
                  <Intro />
                </Route>
                <Route path="*">
                  <Home />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </Router>
      </AudioProvider>
    </main>
  )
}
