import { useState, useEffect, useContext, MouseEvent } from 'react'
import { withRouter, Link } from 'react-router-dom'

/*
import poster from './../assets/img/preview.jpg'
import animation from './../assets/vid/animation.mp4'
*/

import pic1 from './../assets/img/20.jpg'
import pic2 from './../assets/img/3-1.jpg'
import pic3 from './../assets/img/10-1.jpg'

import About from './../components/About'
import Music from './..//components/Music'
import Contact from './../components/Contact'

import { AudioContext, AudioActions } from './../components/Audio'

const covers = [
  {
    title: 'About',
    key: 'about',
    bgColor: 'bg-emerald-500',
    textColor: 'text-emerald-100',
    menuColor: 'text-emerald-500',
    delay: 'delay-100',
    img: pic1
  },
  {
    title: 'Listen',
    key: 'listen',
    bgColor: 'bg-amber-500',
    textColor: 'text-amber-100',
    menuColor: 'text-amber-500',
    delay: 'delay-200',
    img: pic2,
    video: false
    /*
    video: {
      src: animation,
      poster: poster
    }
    */
  },
  {
    title: 'Contact',
    key: 'contact',
    bgColor: 'bg-rose-500',
    textColor: 'text-rose-100',
    menuColor: 'text-rose-500',
    delay: 'delay-300',
    img: pic3
  }
]

function Home({ history }: { history: any }): JSX.Element {
  const { dispatch } = useContext(AudioContext)

  const getPath = (pathname: string): string => {
    const parts = pathname.split('/').filter((e: string) => e.length)
    const paths = covers.map((c) => c.key)
    const path = parts.length ? parts[0] : 'home'
    if (paths.indexOf(path) > -1) {
      return path
    }
    return 'home'
  }

  const [view, setView] = useState(getPath(history.location.pathname))
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const unlisten = history.listen((location: any): void => {
      const path = getPath(location.pathname)
      setView(path)
      dispatch({
        action: AudioActions.UPDATE,
        payload: { songIndex: -1, play: false }
      })
    })
    return () => {
      unlisten()
    }
  })

  const getView = (key: string): JSX.Element | null => {
    switch (key) {
      case 'about':
        return <About />
      case 'listen':
        return <Music />
      case 'contact':
        return <Contact />
      default:
        return null
    }
  }

  return (
    <div className="fixed inset-0 flex">
      <aside
        className={[
          'relative z-50 h-full max-w-sm overflow-hidden',
          menu === true ? 'w-full' : 'w-16',
          'transition-all ease-in-out duration-700'
        ].join(' ')}
      >
        <div className="flex flex-col flex-nowrap h-full justify-between">
          <div className="flex-initial px-4 pt-8">
            <button
              className={['btn-menu', menu ? 'menu-open' : ''].join(' ')}
              type="button"
              onClick={() => setMenu(!menu)}
            >
              <span></span>
            </button>
          </div>
          <ul className=" flex-auto px-4 py-4 text-center flex flex-col justify-center whitespace-nowrap">
            {covers.map((item) => {
              return (
                <li
                  key={item.key}
                  className={[
                    'relative block py-8',
                    'font-cursive text-4xl',
                    menu === true ? 'top-0 opacity-100' : '-top-8 opacity-0',
                    'transition-all duration-700',
                    item.delay
                  ].join(' ')}
                >
                  <Link
                    to={`/${item.key}`}
                    className={[
                      'block transition duration-700',
                      item.menuColor,
                      'hover:text-black'
                    ].join(' ')}
                    onClick={() => setMenu(false)}
                  >
                    {item.title}
                  </Link>
                </li>
              )
            })}
            <li
              className={[
                'relative block py-8 mt-8',
                menu === true ? 'top-0 opacity-100' : '-top-8 opacity-0',
                'transition-all duration-700 delay-500'
              ].join(' ')}
            >
              <Link
                to="/"
                className="block font-cursive text-xl transition duration-700"
              >
                Watch Intro
              </Link>
            </li>
          </ul>
          <div
            className={[
              'flex-initial px-4 pb-8 text-center whitespace-nowrap',
              menu === true ? 'opacity-100' : 'opacity-0',
              'transition-all duration-300'
            ].join(' ')}
          >
            <p className="text-gray-400">2021 © Chantal Noeline</p>
            <p className="text-sm text-gray-400">
              Created by{' '}
              <a href="https://graphique.io" target="_blank" rel="noreferrer">
                graphique.io
              </a>
            </p>
          </div>
        </div>
      </aside>
      <div className="flex-auto h-full overflow-hidden">
        <div className="flex h-full">
          {covers.map((cover, c) => {
            return (
              <div
                key={cover.key}
                className={[
                  'relative h-full',
                  view === 'home'
                    ? 'w-1/3'
                    : view === cover.key
                    ? 'w-full sm:w-10/12'
                    : 'w-0 sm:w-1/12',
                  cover.bgColor,
                  'border-l-2 border-white',
                  'transition-all ease-out duration-500'
                ].join(' ')}
              >
                <a
                  className={[
                    'block absolute inset-0 z-10 overflow-hidden',
                    view === 'home' || view !== cover.key
                      ? 'cursor-pointer'
                      : ''
                  ].join(' ')}
                  onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                    if (view === 'home' || view !== cover.key) {
                      history.push(`/${cover.key}`)
                      e.preventDefault()
                    }
                  }}
                >
                  {cover.video ? /*(
                <video
                  className="block w-full h-full object-cover"
                  poster={cover.video.poster}
                  muted
                  autoPlay
                >
                  <source src={cover.video.src} type="video/mp4" />
                </video>
              )*/ null : (
                    <img
                      className={`block w-full h-full object-cover drop delay-${
                        100 * c
                      }`}
                      src={cover.img}
                      data-loaded="false"
                      onLoad={(e) =>
                        e.currentTarget?.setAttribute('data-loaded', 'true')
                      }
                    />
                  )}
                  {view !== cover.key ? (
                    <div
                      className={[
                        'block absolute z-20 inset-0 opacity-0 hover:opacity-100',
                        'transition duration-500',
                        cover.bgColor,
                        'bg-opacity-50'
                      ].join(' ')}
                    >
                      <span className="absolute left-4 transform rotate-90 origin-bottom-left font-cursive text-white text-xl sm:text-4xl">
                        {cover.title}
                      </span>
                    </div>
                  ) : null}
                  <div
                    className={[
                      'absolute z-50 w-full sm:w-auto inset-y-0',
                      cover.textColor,
                      cover.bgColor,
                      'bg-opacity-90',
                      cover.key === view
                        ? 'right-0 opacity-100'
                        : '-right-full opacity-0',
                      'transition-all ease-out duration-500'
                    ].join(' ')}
                  >
                    <button
                      className="absolute top-4 right-4 btn-close"
                      onClick={() => history.push('/home')}
                    >
                      <span></span>
                    </button>
                    {getView(cover.key)}
                  </div>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Home)
